import { TrashIcon } from "@heroicons/react/24/solid";
import useFavoriteSearchMutations from "../hooks/useFavoriteSearchMutations";
import Spinner from "./Spinner";

export default function DeleteSearchButton({
  searchId,
  isFavorite,
}: {
  searchId: string;
  isFavorite?: boolean;
}) {
  const { removeFavoriteSearch, deletingFavoriteSearch } =
    useFavoriteSearchMutations();

  function deleteFavoriteSearch(e) {
    e.preventDefault();
    removeFavoriteSearch(searchId);
  }

  return (
    <button
      className="text-sm font-medium text-orange-500 hover:text-orange-400 cursor-pointer disabled:opacity-50 flex gap-x-2"
      onClick={deleteFavoriteSearch}
      disabled={deletingFavoriteSearch}
    >
      {deletingFavoriteSearch ? (
        <Spinner className="h-5 w-5" />
      ) : (
        <TrashIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
      )}
      Eliminar
    </button>
  );
}
