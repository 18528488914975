import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import {
  BellIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { BellSlashIcon as BellIconSolid } from "@heroicons/react/24/solid";
import { useSession } from "next-auth/react";
import Link from "next/link";
import {
  FavoriteSearch,
  useDeleteAllFavoriteSearchesMutation,
  useGetUserFavoriteSearchesQuery,
} from "../graphql/generated/graphql";
import useFavoriteSearchMutations from "../hooks/useFavoriteSearchMutations";
import { FILTER_CATEGORIES } from "../utils/constants";
import { getFilterValue, queryToObject } from "../utils/favoriteSearches";
import { classNames } from "../utils/formatters";
import DeleteSearchButton from "./DeleteSearchButton";
import Spinner from "./Spinner";
import WatchlistEmptyState from "./WatchlistEmptyState";

export function FavoriteSearchesList({
  loading,
  searches,
  refetchVehicleSearches,
}: {
  loading?: boolean;
  searches: FavoriteSearch[];
  refetchVehicleSearches?: () => void;
}) {
  const { updateFavoriteSearch, updatingFavoriteSearch } =
    useFavoriteSearchMutations(refetchVehicleSearches);

  function toggleNotifications(e, search) {
    e.preventDefault();
    updateFavoriteSearch(search._id, !search.notify);
  }

  return (
    <ul role="list" className="space-y-2">
      {searches.map((search) => {
        const queryObject = queryToObject(search.query);
        return (
          <li
            key={search._id}
            className="block hover:bg-orange-50 rounded-md border border-gray-200 cursor-pointer"
          >
            <Link href={`/autosusados?${search.query}`}>
              <div className="relative flex flex-col px-4 py-4 sm:px-6  gap-y-3">
                <ArrowTopRightOnSquareIcon className="absolute right-2 top-2 h-5 w-5 text-gray-400 hover:text-orange-500" />
                <div className="flex min-w-0 flex-1">
                  <div>
                    {Object.keys(queryObject).map((key) => (
                      <p key={`${search._id}-${key}`} className="text-sm">
                        <p className="font-bold block sm:inline text-gray-700">
                          {FILTER_CATEGORIES[key]?.label}:{" "}
                        </p>
                        <p className="capitalize block sm:inline text-gray-600">
                          {getFilterValue(key, queryObject)}
                        </p>
                      </p>
                    ))}
                  </div>
                </div>
                <div className="pt-3 flex flex-col sm:flex-row gap-x-4 border-t border-gray-200">
                  <button
                    className="text-sm font-medium text-orange-500 hover:text-orange-400 cursor-pointer disabled:opacity-50 flex gap-x-2"
                    onClick={(e) => toggleNotifications(e, search)}
                    disabled={updatingFavoriteSearch || loading}
                  >
                    {search.notify ? (
                      <BellIconSolid
                        className="text-blue-500 h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                    ) : (
                      <BellIcon
                        className="text-gray-900 h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                    )}

                    {search.notify
                      ? "Silenciar notificaciones"
                      : "Activar notificaciones"}
                  </button>
                  <DeleteSearchButton searchId={search._id} isFavorite />
                </div>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default function FavoriteSearches({ limit }: { limit?: number }) {
  const { data: sessionData } = useSession();
  const { user } = sessionData || {};
  const userId = user?.id;

  const {
    data,
    loading,
    refetch: refetchFavoriteSearches,
  } = useGetUserFavoriteSearchesQuery({
    skip: !userId,
  });

  const { getUserFavoriteSearches: favoriteSearches } = data || {};

  const [deleteAll, { loading: deletingAll }] =
    useDeleteAllFavoriteSearchesMutation({
      onCompleted: ({ deleteAllFavoriteSearches }) => {
        console.log(deleteAllFavoriteSearches);
        // the TS error below is not valid
        if (deleteAllFavoriteSearches === true) {
          refetchFavoriteSearches();
        }
      },
    });

  let content = (
    <div className="pb-8">
      <WatchlistEmptyState isFavoriteSearch />
    </div>
  );
  const searches = limit
    ? favoriteSearches?.filter((_, idx) => idx < limit)
    : favoriteSearches;

  if (favoriteSearches?.length) {
    content = <FavoriteSearchesList loading={loading} searches={searches} />;
  }

  const ActionIcon = limit ? MagnifyingGlassIcon : TrashIcon;

  return (
    <div>
      <div className="flex justify-between px-4">
        <div>
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 h-">
            Tus Búsquedas Favoritas
          </h2>
        </div>
        {favoriteSearches?.length ? (
          <div className="flex items-center">
            <div className="flex items-center mr-5">
              <div className="flex items-center">
                <ActionIcon
                  className={classNames(
                    "text-gray-900",
                    "h-5 w-5 flex-shrink-0"
                  )}
                  aria-hidden="true"
                />
              </div>
              <p className="sr-only">Eliminar todas</p>
              {limit ? (
                <Link href="/favorites">
                  <span className="ml-2 cursor-pointer text-sm font-medium text-orange-500 hover:text-orange-400">
                    Ver todas
                  </span>
                </Link>
              ) : (
                <button
                  className="ml-2 text-sm font-medium text-orange-500 hover:text-orange-400 disabled:opacity-40 disabled:cursor-not-allowed"
                  disabled={loading || deletingAll}
                  onClick={() => deleteAll()}
                >
                  Eliminar todas
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div className="px-4 py-8">
        {loading ? (
          <Spinner containerClassName="h-72 flex items-center justify-center" />
        ) : (
          content
        )}
      </div>
    </div>
  );
}
