import Head from "next/head";
import Layout from "../components/Layout";
import {
  GetVehiclesQuery,
  GetVehiclesDocument,
} from "../graphql/generated/graphql";
import SEO from "../components/SEO";
import { TIER_KEYS } from "../utils/constants";
import { addApolloState, initializeApollo } from "../graphql/client";
import HomepageV2 from "../components/homepage/HomepageV2";
export default function Home({ vehiclesCollection }) {
  return (
    <div>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Head>

      <SEO
        currentURL={`${process.env.NEXT_PUBLIC_ROOT_URL}`}
        title="Movi Autos"
        siteTitle="Autos usados en Costa Rica"
      />

      <HomepageV2 vehiclesCollection={vehiclesCollection} />
    </div>
  );
}

Home.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

export const getStaticProps = async ({ params }) => {
  const apolloClient = initializeApollo({});
  try {
    const { data: collectionData } = await apolloClient.query<GetVehiclesQuery>(
      {
        query: GetVehiclesDocument,
        variables: {
          // limit: 9,
          filters: {
            listingTier: TIER_KEYS.COLLECTION,
            // category: ["suv"],
          },
        },
      }
    );

    const { getVehicles: getCollectionVehicles } = collectionData || {};
    const { data: vehiclesCollection } = getCollectionVehicles || {};

    return addApolloState(apolloClient, {
      props: {
        vehiclesCollection: vehiclesCollection || [],
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 59, // In seconds
    });
  } catch (e) {
    console.log("ERR", e);
    return {
      props: {
        vehiclesCollection: [],
        vehicleFeatured: {},
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 59, // In seconds
    };
  }
};

// SEO
// Search banner below Free cards
