import { HeartIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";

const icons = {
  list: MagnifyingGlassIcon,
  favorites: HeartIcon,
};

export default function GoToWatchlistCard({
  pathname,
  query,
  icon,
  label,
}: {
  pathname: string;
  query?: ParsedUrlQueryInput;
  icon: string;
  label: string;
}) {
  const router = useRouter();

  const goToFavorites = () => {
    router.push({
      pathname,
      query,
    });
  };

  const Icon = icons[icon];
  return (
    <div className="relative cursor-pointer" onClick={goToFavorites}>
      <div className="relative w-full min-h-60 bg-gray-100 aspect-w-1 aspect-h-1 rounded-md overflow-hidden hover:opacity-75 border border-orange-500">
        <div className="flex flex-col justify-center items-center">
          <Icon className="h-10 w-10 text-gray-400" aria-hidden="true" />
          <span className=" font-medium text-orange-500 hover:text-orange-400 cursor-pointer mt-1">
            {label}
          </span>
        </div>
      </div>
    </div>
  );
}
